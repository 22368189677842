var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.isLoadingXeroList
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      _c("ASuccessWithoutReload", {
        attrs: { api: this.api },
        on: { close: _vm.closeSuccessModal },
      }),
      !_vm.isLoadingXeroList
        ? _c("v-data-table", {
            attrs: { items: _vm.filteredData, headers: _vm.dataHeader },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function () {
                    return [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "text-h4 pt-4",
                          attrs: { flat: "", height: "auto" },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mt-3 mx-3" },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mb-3" },
                                    [
                                      _c(
                                        "v-toolbar-title",
                                        {
                                          staticClass:
                                            "font-weight-bold text-h4 my-3",
                                        },
                                        [_vm._v(" Invoices ")]
                                      ),
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-3",
                                          attrs: {
                                            small: "",
                                            to: {
                                              name: "PageUploadXeroInvoice",
                                            },
                                            color: "primary",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "mx-2 my-3" },
                                            [
                                              _vm._v(
                                                " Upload Original Invoices "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("AFilterWithoutRefresh", {
                                    attrs: {
                                      data: _vm.data,
                                      filterHeaders: _vm.filterColumns,
                                    },
                                    on: {
                                      reset: function (data) {
                                        _vm.filteredData = data
                                      },
                                      filter: function (data) {
                                        _vm.filteredData = data
                                      },
                                      refresh: _vm.fetch,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "item.index",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm._v(" " + _vm._s(_vm.data.indexOf(item) + 1) + " "),
                    ]
                  },
                },
                {
                  key: "item.invoice_link",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.invoice_link != null
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: item.invoice_link,
                                target: "_blank",
                              },
                            },
                            [_vm._v(" View invoice ")]
                          )
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: "item.is_send_email",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.is_send_email == 1
                        ? _c(
                            "v-chip",
                            { attrs: { outlined: "", color: "success" } },
                            [_vm._v(" Yes ")]
                          )
                        : _vm._e(),
                      item.is_send_email == 0
                        ? _c(
                            "v-chip",
                            { attrs: { outlined: "", color: "orange" } },
                            [_vm._v(" No ")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "item.client_invoice_link",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.client_invoice_link != null
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: item.client_invoice_link,
                                target: "_blank",
                              },
                            },
                            [_vm._v(" View invoice ")]
                          )
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: "item.action",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      color: "primary",
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(" Actions "),
                                                _c("v-icon", [
                                                  _vm._v(" mdi-chevron-up "),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    _vm._l(_vm.actions, function (data, index) {
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: index,
                                          staticStyle: { "font-size": "16px" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.executeAction(
                                                data.action,
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(data.name) + " ")]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "item.company_name",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.company_id != null
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "PageCompaniesDetail",
                                  params: { id: item.company_id },
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.company_name) + " ")]
                          )
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: "item.invoice_no",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c("ACopyAbleText", { attrs: { text: item.invoice_no } }),
                    ]
                  },
                },
              ],
              null,
              false,
              3409065726
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }